.modalCheckoutForm {
    position: absolute;
    width: 100%;
    height: 200vh;
    left: 0;
    top: 50px;
    background-color: rgba(0, 0, 0, 0.5);
}

.checkoutForm {
    position: absolute;
    overflow: auto;
    top: 50%;
    transform: translate(0, -50%);
    border: 1px solid black;
    background-color: white;
    padding: 15px;
    z-index: 100;
    width: 100%;
    /* padding: 20px 20px 20px 20px; */
    /* padding: 30px; */
    border-radius: 20px;
    max-height: 90vh;
}

.closeCheckouMenu {
    text-align: right;
}
.checkoutFormTitle {
    margin-bottom: 20px;
    text-align: center;
}

.checkoutFormInput {
    transform: translate3d(0, 0, 0);
    width: 100%;
    border: none;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 20px;
    margin-bottom: 15px;
    color: black;
    appearance: none; /* скрываем стандартный селектор */
    -webkit-appearance: none;
    -moz-appearance: none;
}
select > option {
    background-color: #fff;
    position: absolute;
    top: 500%;
    left: 0;
    right: 0;
}
select:focus > option:checked {
    position: absolute;
    top: 500%;
    left: 0;
    right: 0;
}
.checkoutFormCommentsArea {
    width: 100%;
    border: none;
    background-color: #f2f2f2;
    padding: 15px;
    border-radius: 20px;
    margin-bottom: 15px;
    resize: none;
    color: black;
}

.checkoutOrderBtn {
    background-color: rgb(40, 80, 82);
    color: white;
    border-radius: 15px;
    border: none;
    padding: 10px;
}

.checkoutDisplayOption {
    display: block;
}

.checkoutNotDisplayOption {
    display: none;
}
