.SearchBarWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    margin-right: 10px;
}
.SeracBar-ModalWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Полупрозрачный цвет фона */
    display: flex;
    align-items: center;
    justify-content: center;
}

.SearcBar-ModalContent {
    background-color: white;
    border-radius: 5px;
    padding: 20px 20vw 20px 20vw; /* или любое другое значение для внутреннего отступа */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.SearcBar-ModalWrapper {
    display: flex;
    gap: 5%;
    width: 50vw;
}

.SearchBar-Input {
    width: 100%;
}
