.breadcrumbsWrapper {
    display: flex;
    gap: 20px;
    background-color: white;
    padding: 20px 3% 20px 3%;
    position: fixed;
    width: 100%;
    z-index: 50;
}
.breadcrumbsItemList {
    display: flex;
    gap: 5px;
    list-style: none;
}

.breadcrumbsItemContainer {
    cursor: pointer;
    text-align: center;
}
.breadcrumbsItemContainer:last-child > a {
    text-decoration: none;
    color: rgb(40, 80, 82);
}

.breadcrumbsItemLink {
    list-style: none;
    color: blue;
    text-decoration: underline;
}

/* .breadcrumbsItemLinkDisabled {
    color: black;
    pointer-events: none;
    text-decoration: none;
} */
